import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FileImageBankPipe } from '@componentpath/pipes/slug-name.pipe';
import { FormsModule } from '@angular/forms';
import { faCloudUpload, faImage, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import { PromoPopupComponent } from '@template/mps-basic/parts/promo-popup/promo-popup.component';
import { BankCompanyPopupComponent } from '@template/mps-basic/parts/bank-company-popup/bank-company-popup.component';
import { BankPlayerPopupComponent } from '@template/mps-basic/parts/bank-player-popup/bank-player-popup.component';
import { QrisPopupComponent } from '@template/mps-basic/parts/qris-popup/qris-popup.component';
import { NgbModal, NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Component({
  selector: 'app-deposit',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FormsModule, FontAwesomeModule, FileImageBankPipe, NgbAccordionModule],
  templateUrl: './deposit.component.html',
  styleUrl: './deposit.component.scss'
})

export class DepositComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public data: DataService,
    public auth: AuthService,
    public request: RequestService
  ) { }
  private modalService = inject(NgbModal);

  qrisEnable: boolean = false;
  qrisFee: string = "";
  qrisFeeFix: string = "";
  qrisCallback: any = null;

  faCloudUpload: IconProp = faCloudUpload;
  faImage: IconProp = faImage;
  faChevronDown: IconProp = faChevronDown;

  currentUnix = dayjs().unix();

  bank_info: any = {
    player: null,
    company: null
  };
  promo: any = null;
  userProfileData: any = this.auth.userProfileData;
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  playerBankList: any = null;
  companyBankList: any = null;
  promoList: any = null;

  isSubmit: boolean = false;
  successMessage: string = '';
  errorMessage: string = '';

  amount: string = '';
  amountNumberFormat: string = '';
  transaction_fee: any = 0;
  transaction_fee_type: any = 'fixed';
  total: string = '';
  transaction_date: string = '';
  transfer_slip: any = '';
  transaction_note: string = '';
  promotion_type: string = '';
  promotion_id: string = '';

  popupOpen(name: string): void {
    if (name === 'promo') {
      const activeModal = this.modalService.open(PromoPopupComponent, { size: 'lg', keyboard: false, centered: true });
      activeModal.componentInstance.promoCurrent = this.promo;
      activeModal.result.then(
        (result) => {
          this.promo = result;
          if (this.promo !== null) {
            this.promotion_type = this.promo.type;
            this.promotion_id = this.promo._id;
          }
        }
      );
    } else if (name === 'bankCompany') {
      const activeModal = this.modalService.open(BankCompanyPopupComponent, { size: 'lg', keyboard: false, centered: true });
      activeModal.componentInstance.bankCurrent = this.bank_info.company;
      activeModal.componentInstance.bankList = this.companyBankList;
      activeModal.componentInstance.qrisEnable = this.qrisEnable;
      console.log(activeModal.componentInstance);
      activeModal.result.then(
        (result) => {
          this.updateBankInfo('company', result);
        }
      );
    } else if (name === 'bankPlayer') {
      const activeModal = this.modalService.open(BankPlayerPopupComponent, { size: 'lg', keyboard: false, centered: true });
      activeModal.componentInstance.bankCurrent = this.bank_info.player;
      activeModal.componentInstance.bankList = this.playerBankList;
      activeModal.result.then(
        (result) => {
          this.updateBankInfo('player', result);
        }
      );
    } else if (name === 'qris') {
      const activeModal = this.modalService.open(QrisPopupComponent, { size: 'lg', keyboard: false, centered: true, backdrop: 'static' });
      activeModal.componentInstance.qrisCallback = this.qrisCallback;
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  updateBankInfo(type: string, value: any): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (type === 'player') {
        if (this.playerBankList.data.records.length > 0) {
          this.bank_info.player = value;
          this.bank_info.company = this.companyBankList.data['1']['0'];
          for (const value of this.companyBankList.data['1']) {
            if (this.bank_info.player.bank === value.bank) {
              this.bank_info.company = value;
              if (this.bank_info.company.system_bank !== undefined && this.bank_info.company.system_bank.transaction_fee !== null) {
                this.transaction_fee = this.bank_info.company.system_bank.transaction_fee;
                this.transaction_fee_type = 'fixed';
              } else {
                this.transaction_fee = 0;
                this.transaction_fee_type = 'fixed';
              }

              break;
            }
          }
        }
      } else {
        if (this.companyBankList.data['1'].length > 0) {
          this.bank_info.company = value;
          if (this.bank_info.company._id === 'qris') {
            this.transaction_fee = parseFloat(this.qrisFee);
            this.transaction_fee_type = this.qrisFeeFix;
          } else {
            if (this.bank_info.company.system_bank.transaction_fee !== null && this.bank_info.company.system_bank.transaction_fee !== undefined) {
              this.transaction_fee = this.bank_info.company.system_bank.transaction_fee;
              this.transaction_fee_type = 'fixed';
            } else {
              this.transaction_fee = 0;
              this.transaction_fee_type = 'fixed';
            }
          }
        }
      }
    }
  }

  updateBankInfoInit(): void {
    if (this.playerBankList !== null && this.companyBankList !== null) {
      if (this.playerBankList.data.records.length > 0) {
        this.bank_info.player = this.playerBankList.data.records[0];
        this.bank_info.company = this.companyBankList.data['1']['0'];
        for (const value of this.companyBankList.data['1']) {
          if (this.bank_info.player.bank === value.bank) {
            this.bank_info.company = value;
            if (this.bank_info.company._id === 'qris') {
              this.transaction_fee = 0;
            } else {
              if (this.bank_info.company.system_bank !== undefined && this.bank_info.company.system_bank.transaction_fee !== null) {
                this.transaction_fee = this.bank_info.company.system_bank.transaction_fee;
              } else {
                this.transaction_fee = 0;
              }
              break;
            }
          }
        }
      }
    }
  }

  filterNumber(event: any): void {
    event.target.value = event.target.value.replace(/\D/g, '');
  }

  amountKeyup(event: any): void {
    this.amount = event.target.value.replaceAll(/\./g, '');

    if (this.amount !== '') {
      event.target.value = this.numberFormat(parseInt(this.amount));
      this.amountNumberFormat = this.numberFormat(parseInt(this.amount));

      const fee: any = this.getFee();
      const total = parseInt(this.amount) - parseInt(fee);
      this.total = 'IDR ' + this.numberFormat(total);
    } else {
      event.target.value = this.amount;
      this.total = 'IDR ' + this.amount;
    }
  }

  numberFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  getFee() {
    if (this.transaction_fee_type === 'fixed') {
      return this.transaction_fee;
    } else {
      if (isNaN(this.transaction_fee * parseInt(this.amount))) {
        return 0;
      } else {
        return Math.ceil(this.transaction_fee * parseInt(this.amount))
      }
    }
  }

  changeTransferSlip(event: any): void {
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      this.transfer_slip = reader.result;
    };
  }

  createDepositSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.amount) {
        this.errorMessage = 'Nominal Transaksi is Required.';
        this.isSubmit = false;
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return;
      } else {
        if (this.bank_info.company._id === 'qris') {
          this.request.getIntegrationQR(this.auth.sessionValue, parseInt(this.amount).toString(), '').subscribe({
            next: (v) => {
              this.isSubmit = false;
              this.qrisCallback = v.data;
              this.popupOpen('qris');
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            },
            complete: () => { }
          });
        } else {
          this.request.createDeposit(this.auth.sessionValue, this.bank_info.player._id, this.bank_info.company._id, parseInt(this.amount).toString(), this.transaction_date, this.transfer_slip, this.transaction_note, this.promotion_type, this.promotion_id).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.successMessage = 'Create Deposit is Success.';
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          })
        }
      }
    }
  }

  async downloadQR(parent: any) {
    let link = document.createElement('a');
    link.href = parent.src;
    link.download = parent.alt;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.getIntegrationConfig(this.auth.sessionValue).subscribe({
        next: (v) => {
          if (v.data.qris === true && v.data.status === true) {
            this.qrisEnable = true;
            this.qrisFee = v.data.qris_fee;
            this.qrisFeeFix = v.data.qris_fee_fix_amount;
          }
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });

      this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
        next: (v) => {
          this.companyBankList = v;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
      this.request.getBankPlayerList(this.auth.sessionValue, this.userProfileData._id).subscribe({
        next: (v) => {
          this.playerBankList = v;
          this.updateBankInfoInit();
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'deposit') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }
}
