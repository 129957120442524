import { Component, Input, inject, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RequestService } from '@componentpath/services/request.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-promo-popup',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './promo-popup.component.html',
  styleUrl: './promo-popup.component.scss'
})
export class PromoPopupComponent {
  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    public request: RequestService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';

  @Input() promoCurrent: any = null;

  modal = inject(NgbActiveModal);

  promoList: any = null;

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  type: string = 'all';
  keyword: string = '';
  promoShow: any = [];

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('DD MMMM YYYY');
  }

  typeClick(value: any) {
    this.type = value;
    this.changeFilter();
  }

  keywordSubmit(event: any) {
    this.keyword = event.target.value;
    this.changeFilter();
  }

  changeFilter() {
    this.promoShow = [];
    for (const value of this.promoList.data) {
      if (this.keyword === '') {
        if (this.type === 'all') {
          this.promoShow.push(value);
        } else {
          if (this.type === value.type) {
            this.promoShow.push(value);
          }
        }
      } else {
        if (this.type === 'all') {
          if (value.title.toLowerCase().includes(this.keyword.toLowerCase())) {
            this.promoShow.push(value);
          }
        } else {
          if (this.type === value.type && value.title.toLowerCase().includes(this.keyword.toLowerCase())) {
            this.promoShow.push(value);
          }
        }
      }
    }
  }

  promoClick(value: any) {
    this.promoCurrent = value;
    this.popupClose();
  }

  noPromotion() {
    this.promoCurrent = null;
    this.modal.close(null);
  }

  popupClose() {
    this.modal.close(this.promoCurrent);
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.getPromoList().subscribe({
        next: (v) => {
          this.promoList = v;
          this.promoShow = this.promoList.data;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      });
    }
  }
}
