import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { SidebarMenuComponent } from '@template/mps-basic/parts/sidebar-menu/sidebar-menu.component';
import dayjs from 'dayjs';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleChevronLeft, faCircleChevronRight, faWallet, faRotateRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-transaction',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, SidebarMenuComponent, FontAwesomeModule],
  templateUrl: './transaction.component.html',
  styleUrl: './transaction.component.scss'
})
export class TransactionComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService
  ) { }

  faCircleChevronLeft: IconProp = faCircleChevronLeft;
  faCircleChevronRight: IconProp = faCircleChevronRight;
  faWallet: IconProp = faWallet;
  faRotateRight: IconProp = faRotateRight;

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  transaction: any = null;
  transactionDetail: any = null;
  transactionDetailLoading: any = null;

  type: number = 0;
  status: any = '';
  created_at: any = '';

  page: number = 1;
  limit: number = 10;
  pagination: Array<any> = [];

  clickBack() {
    this.transactionDetailLoading = null;
    this.transactionDetail = null;
  }

  IDRFormat(value: number) {
    return Intl.NumberFormat('id-ID').format(value);
  }

  dateFormat(value: string) {
    return dayjs(value + 'Z').format('DD/MMM/YYYY hh:mm A');
  }

  pageChange(page: number) {
    this.page = page;
    this.getData();
  }

  refresh() {
    this.getData();
  }

  typeChange(event: any) {
    this.transactionDetailLoading = null;
    this.page = 1;
    this.type = event.target.value;
    this.getData();
  }

  dateChange(event: any) {
    this.transactionDetailLoading = null;
    this.page = 1;
    if (event.target.value === '') {
      this.created_at = '';
    } else if (event.target.value === 'today') {
      this.created_at = {
        start: dayjs().format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === '7Before') {
      this.created_at = {
        start: dayjs().subtract(7, 'day').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === '14Before') {
      this.created_at = {
        start: dayjs().subtract(14, 'day').format('YYYY-MM-DD'),
        end: dayjs().format('YYYY-MM-DD')
      };
    } else if (event.target.value === 'weekNow') {
      this.created_at = {
        start: dayjs().subtract(dayjs().day(), 'day').format('YYYY-MM-DD'),
        end: dayjs().add(6 - (dayjs().day()), 'day').format('YYYY-MM-DD')
      };
    } else if (event.target.value === 'weekBefore') {
      this.created_at = {
        start: dayjs().subtract((dayjs().day() + 7), 'day').format('YYYY-MM-DD'),
        end: dayjs().subtract(((6 - (dayjs().day())) + 7), 'day').format('YYYY-MM-DD')
      };
    }
    this.getData();
  }

  statusChange(event: any) {
    this.transactionDetailLoading = null;
    this.page = 1;
    this.status = event.target.value;
    this.getData();
  }

  typeLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "0": "All",
      "1": "Deposit",
      "2": "Withdrawal",
      "3": "Transfer In",
      "4": "Transfer Out",
      "5": "Auto DP",
      "6": "Disbursement",
      // "7": "Integration DP",
      "7": "Deposit",
      "8": "Integration Disbursement"

    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  paymentMethodLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "1": "Bank",
      "2": "Digital Wallet",
      "3": "Cellular Operator",
      "4": "QRIS"
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  cancel(value: any) {
    this.request.cancelIntegrationTransaction(this.auth.sessionValue, value._id, '').subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.refresh();
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
        this.refresh();
      }
    });
  }

  statusLabel(value: any) {
    let label: any = '';
    const codes: any = {
      "3": 'Failed',
      "2": 'Success',
      "1": 'On Hold',
      "0": 'Pending'
    };
    for (const [key, value2] of Object.entries(codes)) {
      if (value.toString() === key) {
        label = value2;
        return label;
      }
    }
  }

  getData() {
    this.transaction = null;
    let query: any = {
      type: this.type,
      date: this.created_at
    }
    if (this.status !== '') {
      query['status'] = this.status;
    }
    this.request.getTransaction(this.auth.sessionValue, query, { page: this.page, limit: this.limit }).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.pagination = [];
          this.transaction = v;
          for (let i = 1; i <= this.transaction.data.max_page; i++) {
            this.pagination.push(i);
          }
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  clickDetail(id: string) {
    this.transactionDetailLoading = true;
    this.request.getTransactionById(this.auth.sessionValue, id).subscribe({
      next: (v: any) => {
        if (v.code == 200) {
          this.transactionDetailLoading = false;
          this.transactionDetail = v;
        }
      },
      error: (e) => {
        console.log(e.error.messages[0].message);
      }
    });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getData();

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'transaksi') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }
}
