<div class="sidebar-menu" [class.sidemenu]="sidemenu">
    @if(currentScreenSize === 'mobile') {
    <div class="title">
        List Transaction
        <fa-icon [icon]="faXmark" (click)="sidemenuToggle()"></fa-icon>
    </div>
    }
    <a routerLink="/saldo" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'saldo'">
            <fa-icon class="icon" [icon]="faMoneyBill1Wave"></fa-icon>Saldo
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <a routerLink="/transaction" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'transaction'">
            <fa-icon class="icon" [icon]="faArrowRightArrowLeft"></fa-icon>Transaksi
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <a routerLink="/statement" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'statement'">
            <fa-icon class="icon" [icon]="faBook"></fa-icon>Statement
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <div class="divider mb-3"></div>
    <a routerLink="/deposit" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'deposit'">
            <fa-icon class="icon" [icon]="faMoneyBillTransfer"></fa-icon>Deposit
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <a routerLink="/withdrawal" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'withdrawal'">
            <fa-icon class="icon" [icon]="faMoneyBillTransfer"></fa-icon>Withdrawal
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <div class="divider mb-3" (click)="sidemenuToggle()"></div>
    <a routerLink="/promotion">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'promotion'">
            <fa-icon class="icon" [icon]="faPercent"></fa-icon>Promotion
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <a routerLink="/referral" (click)="sidemenuToggle()">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'referral'">
            <fa-icon class="icon" [icon]="faTicket"></fa-icon> Referral
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
    <div class="divider mb-3" (click)="sidemenuToggle()"></div>
    <a routerLink="/contact">
        <div class="sidebar-menu-item mb-3" [class.active]="active === 'contact'">
            <fa-icon class="icon" [icon]="faCircleQuestion"></fa-icon>Hubungi Kami
            @if(currentScreenSize === 'desktop') {
            <fa-icon class="arrow-right" [icon]="faChevronRight"></fa-icon>
            } @else {
            <fa-icon class="arrow-right-mobile" [icon]="faCaretRight"></fa-icon>
            }
        </div>
    </a>
</div>
@if(currentScreenSize === 'mobile') {
<div class="trigger-sidebar-menu">
    <div class="trigger-sidebar-menu-inner">
        <fa-icon [icon]="faChevronLeft" (click)="sidemenuToggle()"></fa-icon>
        LIST TRANSACTION
    </div>
</div>
}