import { Component, inject, PLATFORM_ID, Inject, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Component({
  selector: 'app-register-popup',
  standalone: true,
  imports: [FontAwesomeModule, FormsModule],
  templateUrl: './register-popup.component.html',
  styleUrl: './register-popup.component.scss'
})
export class RegisterPopupComponent {
  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private router: Router,
    private data: DataService,
    private auth: AuthService,
    private ActivatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private cookie: SsrCookieService,
  ) {
    this.checkScreen();
  }

  websiteJSONData: any = this.data.websiteJSONData;

  faEye: IconProp = faEye;
  faEyeSlash: IconProp = faEyeSlash;
  isPasswordVisible: boolean = false;
  isPassword2Visible: boolean = false;
  isPinVisible: boolean = false;
  isPin2Visible: boolean = false;

  step: number = 1;

  username: string = '';
  phone: string = '';
  referral: string = '';
  password: string = '';
  password2: string = '';
  pin: string = '';
  pin2: string = '';
  bank: string = '';
  account_number: string = '';
  account_name: string = '';

  referralDisabled: boolean = false;

  login1Submit: boolean = false;
  errorMessage1: string = '';

  login2Submit: boolean = false;
  errorMessage2: string = '';

  login3Submit: boolean = false;
  login3Success: boolean = false;
  errorMessage3: string = '';

  successMessage: string = '';

  bankList: any = [];

  referralValid: any = '';

  activeModal = inject(NgbActiveModal);

  @ViewChild('modalContainer') modalContainer!: ElementRef;

  currentScreenSize: string = '';
  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  public togglePasswordVisibility(key: string): void {
    if (key === 'password') {
      this.isPasswordVisible = !this.isPasswordVisible;
    } else if (key === 'password2') {
      this.isPassword2Visible = !this.isPassword2Visible;
    } else if (key === 'pin') {
      this.isPinVisible = !this.isPinVisible;
    } else {
      this.isPin2Visible = !this.isPin2Visible;
    }
  }

  public pinInput(event: any): void {
    event.target.value = event.target.value.replace(/[^0-9]+/g, '');
  }

  public close(): void {
    this.router.navigate(['/']);
  }

  public checkReferral(): void {
    if (this.referral !== '') {
      this.request.checkReferral(this.referral).subscribe({
        next: (v) => {
          this.referralValid = true;
        },
        error: (e) => {
          this.referralValid = false;
        }
      })
    } else {
      this.referralValid = '';
    }
  }

  public step1Submit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.referralValid = '';
      this.errorMessage1 = '';
      this.login1Submit = true;
      if (!this.username || !this.phone) {
        setTimeout(() => {
          this.errorMessage1 = 'Username or Phone is Required.';
          this.login1Submit = false;
        }, 500);
        return;
      } else {
        this.request.checkUsername(this.username).subscribe({
          next: (v) => {
            if (v.code == 200) {
              if (this.referral !== '' && this.referralValid !== true) {
                this.request.checkReferral(this.referral).subscribe({
                  next: (v) => {
                    this.referralValid = true;
                    this.login1Submit = false;
                    this.step = 2;
                  },
                  error: (e) => {
                    this.login1Submit = false;
                    this.referralValid = false;
                  }
                })
              } else {
                this.referralValid = '';
                this.login1Submit = false;
                this.step = 2;
              }
            }
          },
          error: (e) => {
            this.login1Submit = false;
            this.errorMessage1 = e.error.messages[0].message;
          }
        })
      }
    }
  }

  public step2Submit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.errorMessage2 = '';
      this.login2Submit = true;
      if (!this.password || !this.password2 || !this.pin || !this.pin2) {
        setTimeout(() => {
          this.errorMessage2 = 'Password or Confirm Password or PIN or Confirm PIN is Required.';
          this.login2Submit = false;
        }, 500);
        return;
      } else {
        if (this.pin.length !== 6 || this.pin2.length !== 6) {
          this.errorMessage2 = 'PIN or Confirm PIN must be at least 6 characters.';
          this.login2Submit = false;
          return;
        } else {
          if (this.password !== this.password2) {
            this.errorMessage2 = 'Password or Confirm Password is not Same.';
            this.login2Submit = false;
            return;
          } else if (this.pin !== this.pin2) {
            this.errorMessage2 = 'PIN or Confirm PIN is not Same.';
            this.login2Submit = false;
            return;
          } else {
            this.step = 3;
            this.login2Submit = false;
          }
        }
      }
    }
  }

  public step3Submit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.referralValid = '';
      this.errorMessage1 = '';
      this.login1Submit = true;
      if (!this.username || !this.phone) {
        setTimeout(() => {
          this.errorMessage1 = 'Username or Phone is Required.';
          this.login1Submit = false;
          if (this.modalContainer) {
            this.modalContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }, 500);
        return;
      } else {
        this.request.checkUsername(this.username).subscribe({
          next: (v) => {
            if (v.code == 200) {
              if (this.referral !== '' && this.referralValid !== true) {
                this.request.checkReferral(this.referral).subscribe({
                  next: (v) => {
                    this.referralValid = true;
                    this.login1Submit = false;
                    this.step = 2;
                  },
                  error: (e) => {
                    this.login1Submit = false;
                    this.referralValid = false;
                  }
                })
              } else {
                this.referralValid = '';
                this.login1Submit = false;
                this.step = 2;
              }
            }
          },
          error: (e) => {
            this.login1Submit = false;
            this.errorMessage1 = e.error.messages[0].message;
            if (this.modalContainer) {
              this.modalContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }
        })
      }

      this.errorMessage2 = '';
      this.login2Submit = true;

      if (this.websiteJSONData.configuration.pin) {
        if (!this.password || !this.pin) {
          setTimeout(() => {
            this.errorMessage2 = 'Password or PIN is Required.';
            this.login2Submit = false;
            if (this.modalContainer) {
              this.modalContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }, 500);
          return;
        } else {
          if (this.pin.length !== 6) {
            this.errorMessage2 = 'PIN must be at least 6 characters.';
            this.login2Submit = false;
            if (this.modalContainer) {
              this.modalContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
            }
            return;
          } else {
            this.step = 3;
            this.login2Submit = false;
          }
        }
      } else {
        this.pin = '111111';
        this.step = 3;
        this.login2Submit = false;
      }

      this.errorMessage3 = '';
      this.login3Submit = true;
      if (!this.bank || !this.account_name || !this.account_number) {
        setTimeout(() => {
          this.errorMessage3 = 'Bank or Nomor Rekening or Nama Rekening is Required.';
          this.login3Submit = false;
          if (this.modalContainer) {
            this.modalContainer.nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
          }
        }, 500);
        return;
      }

      if (this.errorMessage1 === '' && this.errorMessage2 === '' && this.errorMessage3 === '') {
        this.request.registerUser('', this.username, this.password, this.pin, this.referral, '', this.phone.toString(), 'id', this.bank, this.account_name, this.account_number).subscribe({
          next: (v) => {
            if (v.code == 200) {
              this.login(this.username, this.password);

              this.login3Submit = false;
              this.login3Success = true;

              this.username = '';
              this.phone = '';
              this.referral = '';
              this.password = '';
              this.password2 = '';
              this.pin = '';
              this.pin2 = '';
              this.bank = '';
              this.account_number = '';
              this.account_name = '';
              this.referralValid = '';

              this.successMessage = 'Register user is Success.';
              this.cookie.delete('ref');
            }
          },
          error: (e) => {
            this.login3Submit = false;
            this.errorMessage3 = e.error.messages[0].message;
          }
        })
      }
    }
  }

  public login(username: string, password: string) {
    this.request.login(username, password).subscribe({
      next: (v) => {
        if (v.code == 200) {
          this.auth.setLogin(v.data, {});
          this.auth.setPINStatus(false);
          this.activeModal.dismiss();
          this.router.navigate(['/']);
        }
      },
      error: (e) => {
        console.log(e);
      }
    })
  }

  public clickBack(step: number): void {
    if (isPlatformBrowser(this.platformId)) {
      this.step = step
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const bankList: any = this.data.bankRegisterJSONData;
      for (const value of bankList.records) {
        if (value.status === true) {
          this.bankList.push(value);
        }
      }
      this.ActivatedRoute.queryParams
        .subscribe(params => {
          if (params['referral'] !== undefined && params['referral'] !== '') {
            this.referral = (params['referral']).toUpperCase();
            this.request.checkReferral(params['referral']).subscribe({
              next: (v) => {
                this.referralValid = true;
                this.referralDisabled = true;
              },
              error: (e) => {
                this.referralValid = false;
              }
            })
          } else {
            let dataRef = this.cookie.get('ref');
            // console.log(dataRef)
            if (dataRef) {
              this.referral = (dataRef).toUpperCase();
              this.request.checkReferral(dataRef).subscribe({
                next: (v) => {
                  this.referralValid = true;
                  this.referralDisabled = true;
                },
                error: (e) => {
                  this.referralValid = false;
                }
              })
            }
          }
        }
        );
    }
  }
}
