import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID, inject, ViewChild, ElementRef, ChangeDetectorRef, HostListener } from '@angular/core';
import { ActivatedRoute, RouterModule, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { AuthService } from '@componentpath/services/auth.service';
import { db } from '@componentpath/db/db';
import { CardGameComponent } from '@template/mps-basic/parts/card-game/card-game.component';
import { LoadingPopupComponent } from '@template/mps-basic/parts/loading-popup/loading-popup.component';
import { AllGamesComponent } from '@template/mps-basic/parts/all-games/all-games.component';
import { faAnglesRight, faMaximize, faMinimize, faStar } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-game',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, AllGamesComponent, CardGameComponent, RouterModule, FontAwesomeModule],
  templateUrl: './game.component.html',
  styleUrl: './game.component.scss'
})
export class GameComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private cdr: ChangeDetectorRef,
    public data: DataService,
    private request: RequestService,
    private auth: AuthService,
    private _Activatedroute: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private router: Router
  ) { }
  private modalService = inject(NgbModal);

  @ViewChild('allGamesInner') allGamesInner!: ElementRef;

  faAnglesRight: IconProp = faAnglesRight;
  faMaximize: IconProp = faMaximize;
  faMinimize: IconProp = faMinimize;
  faStar: IconProp = faStar;

  id: any = '';
  subscription: Subscription = new Subscription();
  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  error: boolean = false;
  game: any = null;

  relatedGamesData: any = null;
  popularGamesData: any = null;

  providerJSONData: any = [];
  provider: any = '';
  totalGameByProvider: number = 0;
  favorite: boolean = false;

  gameURL!: SafeResourceUrl;
  preload: boolean = true;
  isFullscreen: boolean = false;

  allGamesInnerItemWidth: number = 0;

  currentScreenSize: string = '';

  getGames(gameType: any, sortBy: string) {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 7, [''], gameType, [''], '', sortBy, 'desc').subscribe({
        next: (v) => {
          if (sortBy === 'created_at') {
            this.relatedGamesData = [];
            let count = 0;
            for (const value of v['items']) {
              if (count === 6) {
                break;
              }
              if (value.game_id !== this.id) {
                count = count + 1;
                this.relatedGamesData.push(value);
              }
            }
          } else {
            this.popularGamesData = [];
            let count = 0;
            for (const value of v['items']) {
              if (count === 6) {
                break;
              }
              if (value.game_id !== this.id) {
                count = count + 1;
                this.popularGamesData.push(value);
              }
            }
          }
          db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  getGamesByProvider(provider: any) {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 1, [provider], [''], [''], '', 'created_at', 'desc').subscribe({
        next: (v) => {
          db.addGames(v['items']);
          this.totalGameByProvider = v.total;
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  clickFullscreen() {
    this.isFullscreen = !this.isFullscreen;
  }

  getProvider(): void {
    for (const value of this.providerJSONData.records) {
      if (this.game.provider === value._id) {
        this.provider = value;
        this.getGamesByProvider(this.provider._id);
        break;
      }
    }
  }

  clickFavorite(): void {
    this.favorite = !this.favorite;
  }

  openGame(): void {
    if (isPlatformBrowser(this.platformId)) {
      const url: any = this.gameURL;
      // window.open(url.changingThisBreaksApplicationSecurity, "_blank");
      window.location.href = url.changingThisBreaksApplicationSecurity;
    }
  }

  launchGames(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.request.launchGame(this.auth.sessionValue, this.game.provider, window.location.origin, this.id).subscribe({
        next: (v) => {
          if (v.data === null) {
            this.error = true;
          } else {
            if (this.provider.publisher_code !== 'pg') {
              this.gameURL = this.domSanitizer.bypassSecurityTrustResourceUrl(v.data.url as string);
            } else {
              const domParser = new DOMParser();
              let htmlElement = domParser.parseFromString(v.data.url as string, 'text/html');
              const headTag = htmlElement.getElementsByTagName('head')[0];
              let descriptionWebsiteString = "<meta http-equiv=\"cache-control\" content=\"no-cache, no-store, must-revalidate\" />";
              let descriptionWebsite = domParser.parseFromString(descriptionWebsiteString, 'text/xml');
              headTag.prepend(descriptionWebsite.documentElement);
              this.gameURL = this.domSanitizer.bypassSecurityTrustHtml(htmlElement.documentElement.outerHTML);
            }
            this.preload = false;
          }
        },
        error: (e) => {
          this.error = true;
          console.log(e);
        },
        complete: () => { }
      });
    }
  }

  gameClick(item: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.auth.sessionValue !== '') {
        this.modalService.open(LoadingPopupComponent, { size: 'lg', keyboard: false, centered: true });
        if (window.innerWidth >= 992) {
          this.modalService.dismissAll();
          this.router.navigate(['/play/' + item.id]);
        } else {
          this.request.launchGame(this.auth.sessionValue, item.provider, window.location.origin, item.id).subscribe({
            next: (v) => {
              if (v.data !== null) {
                if (item.publisher_code !== 'pg') {
                  this.modalService.dismissAll();
                  const url: any = this.domSanitizer.bypassSecurityTrustResourceUrl(v.data.url as string);
                  // window.open(url.changingThisBreaksApplicationSecurity, "_blank");
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                } else {
                  this.modalService.dismissAll();
                  const domParser = new DOMParser();
                  let htmlElement = domParser.parseFromString(v.data.url as string, 'text/html');
                  const headTag = htmlElement.getElementsByTagName('head')[0];
                  let descriptionWebsiteString = "<meta http-equiv=\"cache-control\" content=\"no-cache, no-store, must-revalidate\" />";
                  let descriptionWebsite = domParser.parseFromString(descriptionWebsiteString, 'text/xml');
                  headTag.prepend(descriptionWebsite.documentElement);
                  const url: any = this.domSanitizer.bypassSecurityTrustHtml(htmlElement.documentElement.outerHTML);
                  // window.open(url.changingThisBreaksApplicationSecurity, "_blank");
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                }
              }
            }
          });
        }
      } else {
        this.router.navigate(['/play/' + item.id]);
      }
    }
  }

  updateWidth() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= 992) {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 224) / 6;
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 184) / 4;
      } else {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 164) / 3;
      }
    }
  }

  ngAfterViewInit() {
    this.updateWidth();
    this.cdr.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateWidth();
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      // const myPopup: any = document.getElementById("myPopup");
      // if (myPopup.style !== undefined) {
      //   myPopup.style.display = "none";
      // }

      // const buttomExpertSa: any = document.getElementsByClassName('buttom-expert-sa');
      // if (buttomExpertSa.style !== undefined) {
      //   buttomExpertSa.style.display = "none";
      // }

      this.subscription = this._Activatedroute.paramMap.subscribe((params) => {
        this.id = params.get('name');
        this.providerJSONData = this.data.providerJSONData;

        this.request.getGameDetails(this.auth.sessionValue, this.id).subscribe({
          next: (v) => {
            this.game = v.data;
            this.favorite = this.game.favorite;
            this.getProvider();
            this.launchGames();

            if (this.game.game_type !== 'SB') {
              this.getGames([this.game.game_type], 'created_at');
              this.getGames([this.game.game_type], 'popularity_point');
            }
          },
          error: (e) => {
            this.error = true;
            console.log(e);
          },
          complete: () => { }
        });
      });

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'games') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (this.subscription) this.subscription.unsubscribe();
    }
  }
}
