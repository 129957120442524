<ng-template #content let-modal>

</ng-template>
<div class="modal-header">
    <h4 class="modal-title">PILIH PROMO</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="popupClose()"></button>
</div>
<div class="modal-body">
    <div class="row p-lg-5 p-2">
        <div class="promotion-filter col-12">
            <div class="row">
                <div class="col-12 col-lg-6">
                    @if(currentScreenSize === 'desktop') {
                    <span class="promotion-filter-type" [class.active]="type === 'all'" (click)="typeClick('all')">
                        All
                    </span>
                    <span class="promotion-filter-type" [class.active]="type === 'deposit'"
                        (click)="typeClick('deposit')">
                        Deposit
                    </span>
                    <span class="promotion-filter-type" [class.active]="type === 'cashback'"
                        (click)="typeClick('cashback')">
                        Cashback
                    </span>
                    } @else {
                    <div class="row mb-4">
                        <span class="promotion-filter-type col-4 text-center" [class.active]="type === 'all'"
                            (click)="typeClick('all')">
                            All
                        </span>
                        <span class="promotion-filter-type col-4 text-center" [class.active]="type === 'deposit'"
                            (click)="typeClick('deposit')">
                            Deposit
                        </span>
                        <span class="promotion-filter-type col-4 text-center" [class.active]="type === 'cashback'"
                            (click)="typeClick('cashback')">
                            Cashback
                        </span>
                    </div>
                    }
                </div>
                <div class="col-12 col-lg-6">
                    <div class="input-group">
                        <input type="text" class="form-control input-blue text-white"
                            placeholder="Enter your promo code here..." (keyup)="keywordSubmit($event)">
                        <span class="input-group-text input-blue text-white">
                            <fa-icon [icon]="faMagnifyingGlass"></fa-icon>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        @if(promoList !== null) {
        @for (item of promoShow; track item;) {
        <div class="col-6 px-4 pb-3 promotion-item"
            [class.promotion-active]="promoCurrent !== null && promoCurrent._id === item._id"
            (click)="promoClick(item)">
            <div class="row bg-white br-2 px-2 rounded">
                <div class="promotion-left col-12 py-2 px-4 d-flex flex-column justify-content-center">
                    <div class="promotion-code-text text-center mb-1">{{item.rules.code}}</div>
                </div>
                <div class="col-12 py-2 px-2 px-lg-4 ps-lg-4 d-flex flex-column justify-content-center">
                    <div class="promotion-title">
                        {{item.rules.title}}
                    </div>
                    <div class="promotion-type">
                        {{item.type}}
                    </div>
                    <!-- <div class="promotion-valid mt-2">
                        @if(item.rules.end_date !== '') {
                        Valid until {{dateFormat(item.rules.end_date)}}
                        }
                    </div> -->
                </div>
            </div>
        </div>
        }
        }
        <div class="col-12 text-center mt-4">
            <div class="btn btn-blue" (click)="modal.close('Save click')">TANPA PROMO</div>
        </div>
    </div>
</div>