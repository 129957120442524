<div class="footer">
    <div class="container-xxl m-auto">
        @if(data.title !== undefined && data.title !== '' && data.content !== undefined && data.content !== '') {
        <div class="footer-container">
            <div ngbAccordion>
                <div ngbAccordionItem [collapsed]="false">
                    <h2 ngbAccordionHeader>
                        <button ngbAccordionButton [innerHTML]="data.title"></button>
                    </h2>
                    <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                            <ng-template>
                                <div [innerHTML]="data.content"></div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }

        <div class="text-center">
            © Copyright 2024 Gacor. All rights reserved.
        </div>
    </div>
</div>