import { Component, Inject, PLATFORM_ID, inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { RequestService } from '@componentpath/services/request.service';
import { FormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PinEditPopupComponent } from '@template/mps-basic/parts/pin-edit-popup/pin-edit-popup.component';
import { PasswordEditPopupComponent } from '@template/mps-basic/parts/password-edit-popup/password-edit-popup.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [HeaderComponent, FooterComponent, FormsModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private request: RequestService,
    private activatedRoute: ActivatedRoute,
  ) { }

  private modalService = inject(NgbModal);

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  isSubmit: boolean = false;
  userProfileData: any = null;

  successMessage: string = '';
  errorMessage: string = '';
  nickname: string = '';
  email: string = '';

  popupOpen(name: string): void {
    if (name === 'password') {
      this.modalService.open(PasswordEditPopupComponent, { size: 'lg', keyboard: false, centered: true });
    } else if (name === 'pin') {
      this.modalService.open(PinEditPopupComponent, { size: 'lg', keyboard: false, centered: true });
    }
  }

  popupClose() {
    this.modalService.dismissAll();
  }

  editProfileSubmit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.successMessage = '';
      this.errorMessage = '';
      this.isSubmit = true;
      if (!this.nickname || !this.email) {
        setTimeout(() => {
          this.errorMessage = 'Nickname or Email is Required.';
          this.isSubmit = false;
        }, 500);
        return;
      } else {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email) === false) {
          setTimeout(() => {
            this.errorMessage = 'Email is not Valid.';
            this.isSubmit = false;
          }, 500);
          return;
        } else {
          this.request.editProfile(this.auth.sessionValue, this.userProfileData._id, this.userProfileData.first_name, this.userProfileData.last_name !== null ? this.userProfileData.last_name : '', this.nickname, this.email, this.userProfileData.mobile_phone, this.userProfileData.mobile_phone_country).subscribe({
            next: (v: any) => {
              if (v.code == 200) {
                this.isSubmit = false;
                this.successMessage = 'Edit Profile is Success.';

                this.auth.setUserData(v.data);
                this.userProfileData = this.auth.userProfileData;

                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                });
              }
            },
            error: (e) => {
              this.isSubmit = false;
              this.errorMessage = e.error.messages[0].message;

              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
            }
          })
        }
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.userProfileData = this.auth.userProfileData;
      this.nickname = this.userProfileData.nickname;
      this.email = this.userProfileData.email;

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'profile') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }

      this.activatedRoute.queryParams
        .subscribe(params => {
          if (params['action'] !== undefined && params['action'] === 'change-password') {
            this.modalService.open(PasswordEditPopupComponent, { size: 'lg', keyboard: false, centered: true });
          }
        }
        );
    }
  }
}
