<div class="page-deposit ticker-none">
    <app-header activeMenu="deposit" />
    <div class="page-inner">
        <div class="container-xxl row m-auto">
            <div class="col-lg-2">
                <app-sidebar-menu [active]="'deposit'" />
            </div>
            <div class="page-inner-container col-lg-10 col-12">
                <div class="title mb-2">
                    DEPOSIT
                </div>
                @if(successMessage !== '') {
                <div class="alert alert-success p-2" role="alert">
                    {{successMessage}}
                </div>
                }
                @if(errorMessage !== '') {
                <div class="alert alert-danger p-2" role="alert">
                    {{errorMessage}}
                </div>
                }
                <form (submit)="createDepositSubmit()">
                    <div class="saldo-container row px-3 my-4">
                        <div class="col-lg-6 col-12 ps-0 mb-4">
                            <div class="card1">
                                <div class="card-inner">
                                    <div class="row mb-4">
                                        <div class="col-6">
                                            @if (bank_info.company !== null) {
                                            {{bank_info.company._id !== 'qris' ? bank_info.company.system_bank.name :
                                            'QRIS'}}
                                            } @else {
                                            &nbsp;
                                            }
                                        </div>
                                        <div class="col-6">
                                            <div class="ml-auto">
                                                @if (bank_info.company !== null) {
                                                @if(bank_info.company.system_bank !== undefined &&
                                                bank_info.company.system_bank.type === 4 ||
                                                bank_info.company.qr_image !== undefined && bank_info.company.qr_image
                                                !== null){
                                                <div class="container-qr">
                                                    <img #parentImage
                                                        [src]="'../assets/orianna/images/bank/'+(bank_info.company | fileImageBank)+'?'+currentUnix"
                                                        [alt]="bank_info.company | fileImageBank">
                                                    <!-- <img class="qr_image" #parentImage [src]="bank_info.company.qr_image"> -->
                                                    <!-- <button type="button"
                                                        class="btn btn-blue btn-block mt-2 download-qr"
                                                        (click)="downloadQR(parentImage)">Download QR</button> -->
                                                </div>
                                                } @else {
                                                @if(bank_info.company._id !== 'qris') {
                                                <img class="bg-white px-2"
                                                    [src]="bank_info.company.system_bank.images.small" />
                                                } @else {
                                                <div class="container-qr"><img #parentImage
                                                        src="../../template/mps-basic/assets/orianna/images/payment-qris.webp" />
                                                </div>
                                                }
                                                }
                                                }@else {
                                                &nbsp;
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div class="account-name">
                                        @if (bank_info.company !== null) {
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_name : ''}}
                                        }@else {
                                        &nbsp;
                                        }
                                    </div>
                                    <div class="account-number">
                                        @if (bank_info.company !== null) {
                                        @if(bank_info.company.system_bank !== undefined &&
                                        bank_info.company.system_bank.type !== 4 || bank_info.company.qr_image !==
                                        undefined && bank_info.company.qr_image ===
                                        null){
                                        {{bank_info.company._id !== 'qris' ? bank_info.company.account_number:
                                        ''}}
                                        }
                                        } @else {
                                        &nbsp;
                                        }
                                    </div>
                                </div>
                                <div class="change-account" (click)="popupOpen('bankCompany')">
                                    @if (bank_info.company !== null) {
                                    GANTI REKENING TUJUAN
                                    } @else {
                                    &nbsp;
                                    }
                                </div>
                            </div>
                            @if(bank_info.company._id !== 'qris'){
                            <div class="card-sep my-4"></div>
                            <div class="form-group mb-3">
                                <label class="label text-white mb-2">Rekening Anda</label>
                                <div class="input-select-bank">
                                    <button type="button" class="form-control"
                                        (click)="popupOpen('bankPlayer')">{{bank_info.player.system_bank.name}}</button>
                                    <fa-icon [icon]="faChevronDown"></fa-icon>
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="mb-2 label text-white">Nama rekening</label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Nama rekening" disabled
                                        [value]="bank_info.player.account_name">
                                </div>
                            </div>
                            <div class="form-group mb-3">
                                <label class="mb-2 label text-white">Nomor rekening</label>
                                <div>
                                    <input type="text" class="form-control" placeholder="Nomor rekening" disabled
                                        [value]="bank_info.player.account_number">
                                </div>
                            </div>
                            }
                        </div>
                        <div class="col-lg-6 col-12">
                            <div class="form-group mb-4">
                                <label class="mb-2 text-white">Jumlah yang di Transfer*</label>
                                <input type="text" class="form-control input-blue input-deposit-amount"
                                    placeholder="Jumlah yang di Transfer" name="amount" (input)="filterNumber($event)"
                                    (keyup)="amountKeyup($event)">
                                <div class="text-small text-end text-white mt-1"><span class="font-italic me-2">Biaya
                                        Admin : </span> IDR {{numberFormat(getFee())}}</div>
                            </div>
                            @if(bank_info.company._id === 'qris') {
                            <div class="row mb-4">
                                <div class="col-12 mb-2">
                                    <div class="btn btn-yellow btn-block" (click)="popupOpen('promo')">PILIH
                                        PROMO</div>
                                </div>
                                <div class="col-12 text-center mt-2">
                                    <div class="promotion-title">
                                        @if(promo !== null) {
                                        {{promo.title}}
                                        }
                                    </div>
                                    <div class="promotion-type">
                                        @if(promo !== null) {
                                        {{promo.type}}
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-4">
                                <label class="mb-2 text-yellow">Saldo yg Diterima</label>
                                <div ngbAccordion class="accordion-deposit">
                                    <div ngbAccordionItem [collapsed]="false">
                                        <h2 ngbAccordionHeader>
                                            <button ngbAccordionButton class="text-center">
                                                @if (total === '') {
                                                IDR 0
                                                } @else {
                                                {{total}}
                                                }
                                            </button>
                                        </h2>
                                        <div ngbAccordionCollapse>
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (amountNumberFormat === '') {
                                                            IDR 0
                                                            } @else {
                                                            IDR {{amountNumberFormat}}
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{numberFormat(getFee())}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            @if(bank_info.company._id !== 'qris') {
                            <div class="row mb-4">
                                <div class="col-7">
                                    <div class="btn-upload cursor-pointer" (click)="file_input.click()">
                                        <fa-icon [icon]="faCloudUpload"></fa-icon>
                                        <div class="btn btn-blue btn-block mt-2">
                                            Upload Bukti Bayar
                                        </div>
                                    </div>
                                    <input class="d-none" type="file" accept="image/*" #file_input
                                        (change)="changeTransferSlip($event)">
                                </div>
                                <div class="col-5">
                                    <div class="btn-image">
                                        @if(transfer_slip === '') {
                                        <fa-icon [icon]="faImage"></fa-icon>
                                        } @else {
                                        <img [src]="transfer_slip" />
                                        }
                                    </div>
                                </div>
                            </div>
                            <textarea class="form-control input-blue mb-4" rows="3" placeholder="Catatan"
                                [(ngModel)]="transaction_note" name="transaction_note"></textarea>
                            <div class="row">
                                <div class="col-12">
                                    <div class="btn btn-yellow btn-block" (click)="popupOpen('promo')">PILIH
                                        PROMO</div>
                                </div>
                                <div class="col-12 text-center mt-2">
                                    <div class="promotion-title">
                                        @if(promo !== null) {
                                        {{promo.title}}
                                        }
                                    </div>
                                    <div class="promotion-type">
                                        @if(promo !== null) {
                                        {{promo.type}}
                                        }
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mt-4">
                                <label class="mb-2 text-yellow">Saldo yg Diterima</label>
                                <div ngbAccordion class="accordion-deposit">
                                    <div ngbAccordionItem [collapsed]="false">
                                        <h2 ngbAccordionHeader>
                                            <button ngbAccordionButton class="text-center">
                                                @if (total === '') {
                                                IDR 0
                                                } @else {
                                                {{total}}
                                                }
                                            </button>
                                        </h2>
                                        <div ngbAccordionCollapse>
                                            <div ngbAccordionBody>
                                                <ng-template>
                                                    <b>Rincian Deposit</b>
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di transfer
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (amountNumberFormat === '') {
                                                            IDR 0
                                                            } @else {
                                                            IDR {{amountNumberFormat}}
                                                            }
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col">
                                                            Biaya Admin
                                                        </div>
                                                        <div class="col text-end">
                                                            IDR {{numberFormat(transaction_fee)}}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div class="row">
                                                        <div class="col">
                                                            Jumlah yang di dapat
                                                        </div>
                                                        <div class="col text-end">
                                                            @if (total === '') {
                                                            IDR 0
                                                            } @else {
                                                            {{total}}
                                                            }
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        <button type="submit" class="btn btn-blue btn-block mt-4"
                            [disabled]="isSubmit || bank_info === null">
                            Buat Deposit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <app-footer [data]="footerData" />
</div>