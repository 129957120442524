import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Inject, PLATFORM_ID, CUSTOM_ELEMENTS_SCHEMA, HostListener } from '@angular/core';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { db } from '@componentpath/db/db';
import { HeaderComponent } from "@template/mps-basic/header/header.component";
import { FooterComponent } from '@template/mps-basic/footer/footer.component';
import { Slideshow1Component } from '@template/mps-basic/parts/slideshow1/slideshow1.component';
import { PopularGamesComponent } from '@template/mps-basic/parts/popular-games/popular-games.component';
import { NewGamesComponent } from '@template/mps-basic/parts/new-games/new-games.component';
import { JackpotComponent } from '@template/mps-basic/parts/jackpot/jackpot.component';
import { AllGamesComponent } from '@template/mps-basic/parts/all-games/all-games.component';
import { faAnglesRight, faWallet, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { register } from 'swiper/element/bundle';
import { RouterModule, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-home',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [FontAwesomeModule, HeaderComponent, Slideshow1Component, PopularGamesComponent, NewGamesComponent, JackpotComponent, AllGamesComponent, FooterComponent, RouterModule],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  categoryslideshowJSONData: any = this.data.categoryslideshowJSONData;
  slideshowData: any = null;
  promoJSONData: any = this.data.promoJSONData;
  promo: any = null;
  footerData: any = [];
  popularGamesData: any = [];
  newGamesData: any = [];
  faAnglesRight: IconProp = faAnglesRight;
  faUserCircle: IconProp = faUserCircle;
  faWallet: IconProp = faWallet;
  faRotateRight: IconProp = faRotateRight;
  paymentMethodData: any = [];
  islogin: boolean = false;
  subscription: Subscription = new Subscription();
  profile: any = null;
  balance: any = null;
  isFixed: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private request: RequestService,
    private router: Router,
    private auth: AuthService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }

  currentScreenSize: string = '';

  topPosToStartShowing = 120;
  @HostListener('window:scroll')
  checkScroll() {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop || document.body.scrollTop || 0;

    if (scrollPosition >= this.topPosToStartShowing) {
      this.isFixed = true;
    } else {
      this.isFixed = false;
    }
  }

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.99px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.99px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  subscriptionData() {
    this.subscription.add(this.auth.getLoginStatus.subscribe({
      next: (v) => {
        this.islogin = v;
        this.profile = this.auth.userProfileData;
      }
    }));
    this.subscription.add(this.auth.getBalancevalue.subscribe({
      next: (v) => {
        this.balance = Intl.NumberFormat('id-ID').format(Math.floor(v));
      }
    }));
  }

  reloadBalance() {
    this.auth.callSession(this.auth.sessionValue);
  }

  private popularGames() {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 8, [''], [''], [''], '', 'popularity_point', 'desc').subscribe({
        next: (v) => {
          this.popularGamesData = v['items'];
          db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  private newGames() {
    db.dbGamesExists().then((e) => {

    })
    if (isPlatformBrowser(this.platformId)) {
      this.request.gamesJSON(1, 8, [''], [''], [''], '', 'latest', 'desc').subscribe({
        next: (v) => {
          this.newGamesData = v['items'];
          db.addGames(v['items']);
        },
        error: (e) => {
          console.log(e);
        },
        complete: () => { }
      })
    }
  }

  public clickBack(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = null;
    }
  }

  public promoDetail(data: any): void {
    if (isPlatformBrowser(this.platformId)) {
      this.promo = data;
    }
  }

  public redirectLogin(): void {
    this.router.navigate(['/login'], { queryParams: { url: this.router.url } });
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkScreen();

      register();
      this.popularGames();
      this.newGames();
      this.subscriptionData();

      this.paymentMethodData = this.data.bankRegisterJSONData;

      this.slideshowData = [];
      const categoryslideshowJSONData: any = this.data.categoryslideshowJSONData;
      if (categoryslideshowJSONData['homepage'] !== undefined && categoryslideshowJSONData['homepage'] !== null) {
        for (const value of categoryslideshowJSONData['homepage']) {
          this.slideshowData.push(value);
        }
      }

      const footerJSONData: any = this.data.footerJSONData;
      for (const value of footerJSONData.records) {
        if (value.type.toLowerCase() === 'default') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
          }
        }
        if (value.type.toLowerCase() === 'home') {
          if (value.contents.en !== '') {
            if (value.contents.en.indexOf('<h1>') === 0 && value.contents.en.indexOf('</h1>') > -1) {
              this.footerData = {
                title: value.contents.en.substring(4, value.contents.en.indexOf('</h1>')),
                content: value.contents.en.substring(value.contents.en.indexOf('</h1>') + 5)
              }
            } else {
              this.footerData = {
                title: '',
                content: value.contents.en
              }
            }
            break;
          }
        }
      }
    }
  }
}
