import { isPlatformBrowser, CommonModule } from '@angular/common';
import { Component, Input, PLATFORM_ID, Inject, ViewChild, ElementRef, ChangeDetectorRef, HostListener, inject, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { faMagnifyingGlass, faAnglesRight } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CardGameComponent } from '@template/mps-basic/parts/card-game/card-game.component';
import { LoadingPopupComponent } from '@template/mps-basic/parts/loading-popup/loading-popup.component';
import { Params, QueryParamsHandling, RouterModule, ActivatedRoute, Router } from '@angular/router';
import { db } from '@componentpath/db/db';
import { RequestService } from '@componentpath/services/request.service';
import { DataService } from '@componentpath/services/data.service';
import { AuthService } from '@componentpath/services/auth.service';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BreakpointObserver } from '@angular/cdk/layout';
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-all-games',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, RouterModule, FormsModule, FontAwesomeModule, CardGameComponent, NgbDropdownModule, MatCheckboxModule],
  templateUrl: './all-games.component.html',
  styleUrl: './all-games.component.scss'
})
export class AllGamesComponent {
  constructor(
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private request: RequestService,
    private dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private domSanitizer: DomSanitizer,
    private breakpointObserver: BreakpointObserver
  ) {
    this.checkScreen();
  }
  private modalService = inject(NgbModal);

  @ViewChild('allGamesInner') allGamesInner!: ElementRef;
  @ViewChild('scrollAnchor', { static: false }) scrollAnchor!: ElementRef;

  subscription: Subscription = new Subscription();

  @Input() autoScroll: boolean = true;
  @Input() filterAll: boolean = true;
  @Input() filterCategory: boolean = false;
  @Input() filterSearch: boolean = false;
  @Input() seeAll: boolean = true;
  @Input() loadMore: boolean = false;
  @Input() lobby: boolean = false;
  @Input() minHeight: boolean = false;

  @Input() page: number = 1;
  @Input() perPage: number = 12;
  @Input() providers: Array<any> = [];
  @Input() providerLists: Array<any> = [''];
  @Input() gameTypes: Array<any> = [];
  @Input() tags: Array<any> = [''];
  @Input() searchValue: string = '';
  @Input() data: Array<any> = [];

  allGamesInnerItemWidth: number = 0;

  gameTypesLabel: string = '';
  tagsLabel: string = '';
  providersLabel: string = '';
  sortByLabel: string = '';
  routeParamsID: string = '';

  qParams: Params = {};
  currentPath: string = '';
  currentSecondPath: string = '';
  totalPages: number = 0;

  type: string = '';
  typeLists: Array<any> = [];
  sort: string = 'popular';
  sortBy: string = 'popularity';
  categoryproviderJSONData: any = [];
  providerListsAll: Array<any> = [];
  arrayProvidersCat: Array<any> = [];

  faMagnifyingGlass: IconProp = faMagnifyingGlass;
  faAnglesRight: IconProp = faAnglesRight;

  isLoad: boolean = false;
  selectedOptions: any[] = [];
  selectedTags: any[] = [];
  searchedOptions: any[] = [];
  searchGameInput: string = '';
  searchGameInputCategories: string = '';
  selectedOption: string = '';

  searchInputProvider: string = '';
  searchGameInputProvider: string = '';
  selectedOptionsProvider: any[] = [];
  searchedOptionsProvider: any[] = [];
  preload: boolean = true;

  currentScreenSize: string = '';

  checkScreen() {
    let displayNameMap = new Map([
      ['(max-width: 991.98px)', 'mobile'],
      ['(min-width: 992px)', 'desktop']
    ]);
    this.breakpointObserver.observe([
      "(max-width: 991.98px)",
      "(min-width: 992px)"
    ]).subscribe(result => {
      for (const query of Object.keys(result.breakpoints)) {
        if (result.breakpoints[query]) {
          this.currentScreenSize = displayNameMap.get(query) ?? 'desktop';
        }
      }
    });
  }

  gameClick(item: any) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.auth.sessionValue !== '') {
        this.modalService.open(LoadingPopupComponent, { size: 'lg', keyboard: false, centered: true });
        if (window.innerWidth >= 992) {
          this.modalService.dismissAll();
          this.router.navigate(['/play/' + item.id]);
        } else {
          this.request.launchGame(this.auth.sessionValue, item.provider, window.location.origin, item.id).subscribe({
            next: (v) => {
              if (v.data !== null) {
                if (item.publisher_code !== 'pg') {
                  this.modalService.dismissAll();
                  const url: any = this.domSanitizer.bypassSecurityTrustResourceUrl(v.data.url as string);
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                } else {
                  this.modalService.dismissAll();
                  const domParser = new DOMParser();
                  let htmlElement = domParser.parseFromString(v.data.url as string, 'text/html');
                  const headTag = htmlElement.getElementsByTagName('head')[0];
                  let descriptionWebsiteString = "<meta http-equiv=\"cache-control\" content=\"no-cache, no-store, must-revalidate\" />";
                  let descriptionWebsite = domParser.parseFromString(descriptionWebsiteString, 'text/xml');
                  headTag.prepend(descriptionWebsite.documentElement);
                  const url: any = this.domSanitizer.bypassSecurityTrustHtml(htmlElement.documentElement.outerHTML);
                  window.location.href = url.changingThisBreaksApplicationSecurity;
                }
              }
            }
          });
        }
      } else {
        this.router.navigate(['/play/' + item.id]);
      }
    }
  }

  updateLabel() {
    if (this.sortBy === 'created_at' || this.sortBy === 'latest') {
      this.sortByLabel = 'Latest';
    } else {
      this.sortByLabel = 'Popular';
    }
  }

  getGames(
    reset: boolean = false,
    providers: Array<any>,
    searchValue: string,
    gameTypes: Array<string>,
    tags: Array<string>
  ) {
    if (isPlatformBrowser(this.platformId)) {
      let providerList = [''];
      let valueSort: string = '';
      let sortType: "desc" | "asc" = 'desc';

      if (this.sortBy === 'latest') {
        valueSort = 'created_at'
      } else if (this.sortBy === 'popularity') {
        valueSort = 'popularity_point'
      } else if (this.sortBy === 'name') {
        valueSort = 'title'
        sortType = 'asc'
      } else {
        valueSort = 'popularity_point'
      }

      for (const value of providers) {
        for (const value2 of this.providerListsAll) {
          if (value === value2.code) {
            if (providerList[0] === '') {
              providerList = [];
            }
            providerList.push(value2._id);
            break;
          }
        }
      }

      if (reset) {
        this.page = 1;
      }

      this.request.gamesJSON(
        this.page,
        this.perPage,
        providerList.length ? providerList : [''],
        gameTypes.length ? gameTypes : [''],
        tags.length ? tags : [''],
        searchValue,
        valueSort,
        sortType
      )
        .subscribe({
          next: (v) => {
            if (reset) {
              this.data = v['items'];
            } else {
              this.data = this.data.concat(v['items']);
            }
            this.totalPages = v['totalPages'];
            db.addGames(v['items']);
          },
          error: (e) => {
            console.log(e);
          },
          complete: () => {
            this.preload = false;
          },
        });
    }
  }

  lobbyClick(value: string) {
    if (value === 'All' || this.tags[0] === value) {
      this.tags = [''];
    } else {
      this.tags = [value];
    }

    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  filterSortClick(value: string) {
    this.sortBy = value;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "sort": value.toLowerCase() }, queryParamsHandling: 'merge' });
  }

  filterSortClickCat(value: string) {
    this.sortBy = value;
    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  selectFilterAll(e: any) {
    let value = e.target.value;
    this.type = value;
    if (value === '') {
      this.gameTypes = [''];
    } else if (value === 'slots') {
      this.gameTypes = ['SL'];
    } else if (value === 'live-casino') {
      this.gameTypes = ['LC'];
    } else if (value === 'fish-hunter') {
      this.gameTypes = ['FH'];
    } else if (value === 'sportbook') {
      this.gameTypes = ['SB'];
    } else {
      this.gameTypes = ['ES'];
    }
    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  filterAllClick(value: string) {
    this.type = value;
    if (value === '') {
      this.gameTypes = [''];
    } else if (value === 'slots') {
      this.gameTypes = ['SL'];
    } else if (value === 'live-casino') {
      this.gameTypes = ['LC'];
    } else if (value === 'fish-hunter') {
      this.gameTypes = ['FH'];
    } else if (value === 'sportbook') {
      this.gameTypes = ['SB'];
    } else {
      this.gameTypes = ['ES'];
    }
    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  filterTagClick(value: string) {
    // this.page = 1;
    if (this.tags.indexOf(value) > -1) {
      this.tags.splice(this.tags.indexOf(value), 1);
    } else {
      this.tags.push(value);
    }
    let paramHandling: QueryParamsHandling = "merge";
    let arrayTagsString = encodeURIComponent(JSON.stringify(this.tags))
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "tags": arrayTagsString, filter: null }, queryParamsHandling: paramHandling });
  }

  setCheckTags() {
    if (this.qParams['tags']) {
      let currentTagsVal = JSON.parse(decodeURIComponent(this.qParams['tags']))
      this.tags = currentTagsVal;
      if (this.tags.length === 1) {
        this.tagsLabel = currentTagsVal[0];
      }
    } else {
      this.tags = [];
    }
  }

  onSelectDropdownValue(e: any): void {
    let categoryCode = e.source.id as string;
    let arrayCategories: Array<string> = []
    if (this.qParams['categories']) {
      let currentCategoriesVal = JSON.parse(decodeURIComponent(this.qParams['categories']));
      for (let i = 0; i < currentCategoriesVal.length; i++) {
        arrayCategories.push(currentCategoriesVal[i]);
      }
    }

    let paramHandling: QueryParamsHandling = "merge";

    if (e.checked) {
      arrayCategories.push(categoryCode);
      let arrayCategoriesString = encodeURIComponent(JSON.stringify(arrayCategories))
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "categories": arrayCategoriesString, filter: null }, queryParamsHandling: paramHandling });
    } else {
      const index = arrayCategories.indexOf(categoryCode);
      if (index > -1) {
        arrayCategories.splice(index, 1);
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "categories": encodeURIComponent(JSON.stringify(arrayCategories)), filter: null }, queryParamsHandling: paramHandling });
      }
    }
  }

  setCheckCategories() {
    if (this.qParams['categories']) {
      let currentCategoriesVal = JSON.parse(decodeURIComponent(this.qParams['categories']))
      this.selectedOptions = currentCategoriesVal;
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
      if (currentCategoriesVal.length > 0) {
        for (let i = 0; i < currentCategoriesVal.length; i++) {
          let index = this.searchedOptions.findIndex((x: any) => x.code_small === currentCategoriesVal[i]);
          this.gameTypesLabel = this.searchedOptions[index]["title"]["en"] === 'Fish Hunter' ? 'ARCADE' : this.searchedOptions[index]["title"]["en"];
          this.searchedOptions[index]["checked"] = true;
        }

      } else {
        for (let i = 0; i < this.searchedOptions.length; i++) {
          this.searchedOptions[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptions = [];
      for (let i = 0; i < this.searchedOptions.length; i++) {
        this.searchedOptions[i]["checked"] = false;
      }
    }
  }

  reset() {
    this.arrayProvidersCat = [];
    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  onSelectDropdownValueProviderCat(e: any) {
    const providersID = e.source.id as string;

    if (e.checked) {
      this.arrayProvidersCat.push(providersID);
      this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
    } else {
      const index = this.arrayProvidersCat.indexOf(providersID);
      if (index > -1) {
        this.arrayProvidersCat.splice(index, 1);
        this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
      } else {
        this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
      }
    }
    if (this.arrayProvidersCat.length === 1) {
      let index = this.searchedOptionsProvider.findIndex((x: any) => x.code === this.arrayProvidersCat[0]);
      this.providersLabel = this.searchedOptionsProvider[index]["name"];
    }
  }

  onSelectDropdownValueProvider(e: any): void {
    const providersID = e.source.id as string;
    let arrayProviders: Array<string> = [];

    if (this.qParams['providers']) {
      let currentProvidersVal = JSON.parse(decodeURIComponent(this.qParams['providers']));
      for (let i = 0; i < currentProvidersVal.length; i++) {
        arrayProviders.push(currentProvidersVal[i]);
      }
    }

    let paramHandling: QueryParamsHandling = "merge";

    if (e.checked) {
      arrayProviders.push(providersID);
      let arrayProvidersString = encodeURIComponent(JSON.stringify(arrayProviders));
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers": arrayProvidersString }, queryParamsHandling: paramHandling });
    } else {
      const index = arrayProviders.indexOf(providersID);
      if (index > -1) {
        arrayProviders.splice(index, 1);
        this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "providers": encodeURIComponent(JSON.stringify(arrayProviders)) }, queryParamsHandling: paramHandling });
      }
    }
  }

  setCheckProviders() {
    if (this.qParams['providers']) {
      let currentProvidersVal = JSON.parse(decodeURIComponent(this.qParams['providers']));
      this.selectedOptionsProvider = currentProvidersVal;
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
      if (currentProvidersVal.length > 0) {
        for (let i = 0; i < currentProvidersVal.length; i++) {
          let index = this.searchedOptionsProvider.findIndex((x: any) => x.code === currentProvidersVal[i]);
          this.providersLabel = this.searchedOptionsProvider[index]["name"];
          this.searchedOptionsProvider[index]["checked"] = true;
        }
      } else {
        for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
          this.searchedOptionsProvider[i]["checked"] = false;
        }
      }
    } else {
      this.selectedOptionsProvider = [];
      for (let i = 0; i < this.searchedOptionsProvider.length; i++) {
        this.searchedOptionsProvider[i]["checked"] = false;
      }
    }
  }

  filterSearchChange(event: any) {
    this.searchValue = event.target.value;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "value": this.searchValue.toLowerCase() }, queryParamsHandling: 'merge' });
  }

  filterSearchChangeCat(event: any) {
    this.searchValue = event.target.value;
    this.data = [];
    this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
  }

  loadMoreClick() {
    this.page = this.page + 1;

    if (this.currentPath === 'search') {
      this.getGames(false, this.providers, this.searchValue, this.gameTypes, this.tags);
    } else {
      this.getGames(false, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);

    }
  }

  private updateFiltersFromQueryParams(params: any): void {
    if (params['categories']) {
      this.gameTypes = JSON.parse(decodeURIComponent(params['categories']));
    } else {
      this.gameTypes = [''];
    }
    if (params['tags']) {
      this.tags = JSON.parse(decodeURIComponent(params['tags']));
    } else {
      this.tags = ['']
    }
    if (params['providers']) {
      this.providers = JSON.parse(decodeURIComponent(params['providers']));
    } else {
      this.providers = ['']
    }
    if (params['sort']) {
      this.sortBy = params['sort'];
    } else {
      this.sortBy = 'popularity'
    }
    if (params['value']) {
      this.searchValue = params['value'];
    } else {
      this.searchValue = '';
    }

    this.getGames(true, this.providers, this.searchValue, this.gameTypes, this.tags);
  }

  updateWidth() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth >= 992) {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 100) / 6;
      } else if (window.innerWidth >= 768 && window.innerWidth < 992) {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 60) / 4;
      } else {
        this.allGamesInnerItemWidth = (this.allGamesInner.nativeElement.clientWidth - 40) / 3;
      }
    }
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      register();

      const typeList: any = this.dataService.categoryproviderJSONData;
      this.typeLists = typeList;
      this.searchedOptions = [...this.typeLists];

      this.providerListsAll = this.dataService.providerJSONData['records'] || [];
      this.searchedOptionsProvider = [...this.providerListsAll];

      this.categoryproviderJSONData = this.dataService.categoryproviderJSONData || [];
      const firstGameType = this.gameTypes[0];
      const selectedCategory = this.categoryproviderJSONData.find(
        (value: any) => value.code_small === firstGameType
      );
      if (selectedCategory) {
        this.providerLists = selectedCategory.providers || [];
      }

      const url: any = this.route.url;
      if (url._value.length > 0) {
        this.currentPath = url._value[0].path;
        this.currentSecondPath = url._value[0].path;
      }

      if (this.filterAll) {
        this.sortBy = 'popular';
        this.arrayProvidersCat = [];
        this.searchValue = '';
        this.getGames(true, this.providers, this.searchValue, this.gameTypes, this.tags);
      }

      if (this.currentPath === 'category') {
        this.subscription.add(this.route.params.subscribe(params => {
          this.routeParamsID = params['id'];

          this.sortBy = 'popularity';
          this.arrayProvidersCat = [];
          this.searchValue = '';

          if (this.routeParamsID === 'slots') {
            this.gameTypes = ['SL'];
          } else if (this.routeParamsID === 'live-casino') {
            this.gameTypes = ['LC'];
          } else if (this.routeParamsID === 'fish-hunter') {
            this.gameTypes = ['FH'];
          } else if (this.routeParamsID === 'sportbook') {
            this.gameTypes = ['SB'];
          } else {
            this.gameTypes = ['ES'];
          }

          if (this.routeParamsID === 'live-casino') {
            this.getGames(true, this.providers, this.searchValue, this.gameTypes, this.tags);
          } else {
            this.getGames(true, this.providers, this.searchValue, this.gameTypes, ['']);
          }
        }));
      }

      this.subscription.add(this.route.queryParams
        .subscribe(params => {
          this.qParams = params;
          if (this.currentPath === 'search') {

            this.updateFiltersFromQueryParams(params);

            this.updateLabel();
            this.setCheckProviders();
            this.setCheckCategories();
            this.setCheckTags();

          } else {
            if (params['provider'] !== undefined) {
              setTimeout(() => {
                this.arrayProvidersCat = [];
                this.arrayProvidersCat.push(params['provider']);
                if (this.arrayProvidersCat.length === 1) {
                  let index = this.searchedOptionsProvider.findIndex((x: any) => x.code === this.arrayProvidersCat[0]);
                  this.providersLabel = this.searchedOptionsProvider[index]["name"];
                }

                if (this.gameTypes.includes('LC')) {
                  this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, this.tags);
                } else {
                  this.getGames(true, this.arrayProvidersCat, this.searchValue, this.gameTypes, ['']);
                }
              }, 500);
            }
          }

        }
        ));
    }
  }

  ngAfterViewInit() {
    this.updateWidth();
    this.cdr.detectChanges();

    if (isPlatformBrowser(this.platformId)) {
      const observer = new IntersectionObserver(
        () => {
          if (this.page < this.totalPages) {
            this.loadMoreClick();
          }
        },
        { threshold: 1 }
      );

      observer.observe(this.scrollAnchor.nativeElement);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateWidth();
  }
}
